<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTPanel title="Traductions">
          <TranslationsForme :textGuid="textGuid" />
        </KTPanel>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TranslationsForme from "./TranslationsForme.vue";
export default {
  components: { TranslationsForme },
  props: ["textGuid"],
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Traductions", route: "translations" },
      { title: "Listing" },
    ]);
  },
  methods: {},
};
</script>

<template>
  <v-data-table
    :headers="headers"
    :items="translations"
    item-key="name"
    class="elevation-1 row-pointer"
    @click:row="handleClick"
  >
  </v-data-table>
</template>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
<script>
import ApiService from "@/core/services/api.service";
export default {
  props: ["textGuid"],
  async created() {
    await this.loadTranslations();
  },
  data() {
    return {
      selected: null,
      headers: [
        {
          text: "Module",
          align: "start",
          sortable: false,
          value: "module",
        },
        { text: "Priorité", value: "priority", sortable: false },
        {
          text: "Langue originale",
          value: "originalLanguage",
          sortable: false,
        },
        { text: "Langue cible", value: "targetLanguage", sortable: false },
        { text: "Nombre de mot", value: "numberOfWords", sortable: false },
        {
          text: "date d'ajout",
          value: "date",
          sortable: false,
        },
        {
          text: "Commencé le",
          value: "assignedAt",
          sortable: false,
        },
        {
          text: "Terminé le",
          value: "finishedAt",
          sortable: false,
        },
        {
          text: "Traduit par",
          value: "assignedTo",
          sortable: false,
        },
      ],
      translations: [],
    };
  },
  methods: {
    handleClick(a) {
      this.$router.push({
        name: "one-translation",
        query: { translationGuid: a.guid },
      });
    },
    async loadTranslations() {
      const response = await ApiService.get(
        `v1/Texts/${this.textGuid}/Translations`
      );
      if (response.status == 200 && response.data != null) {
        this.translations = response.data;
      }
    },
  },
};
</script>
